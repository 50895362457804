import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div>
      <div className="footer-main">
        <div className="footer-items container">
          <div className="row">
            <div className="col-md-3">
              <ul>
                <Link to="/about">
                  <li>Why Tech Pacific</li>
                </Link>
                <Link to="/success-stories">
                  <li>Success Stories</li>
                </Link>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <Link to="">
                  <li>Terms and Conditions</li>
                </Link>
                <Link to="">
                  <li>Privacy Policy</li>
                </Link>
                <Link to="">
                  <li>Cookie Preferences</li>
                </Link>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <Link to="/contact">
                  <li>Contact Us</li>
                </Link>
              </ul>
              <div className="footer-number-section">
                <ul>
                  <li>General Inquiries</li>
                  <a href="tel:+94 11 488 7877">
                    <li>+94 11 488 7877</li>
                  </a>
                </ul>
              </div>
              <div className="footer-main-section">
                <ul>
                  <li>Email</li>
                  <a href="mailto:info@techpac.lk">
                    <li>info@techpac.lk</li>
                  </a>
                </ul>
              </div>
            </div>
            <div className="col-md-3 career-item">
              <div className="footer-main-section">
                <ul>
                  <Link to="">
                    <li>Careers</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="social-section container">
          <div className="social-icons d-flex">
            <div className="fb-icon px-1">
              <a
                href="https://www.facebook.com/techpaclk"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/footer/Social_media_FB.png"
                  width={40}
                  alt="techpac"
                />
              </a>
            </div>
            <div className="in-icon px-1">
              <a
                href="https://www.linkedin.com/company/tech-pacific-lanka/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../images/footer/Social_media_link.png"
                  width={40}
                  alt="techpac"
                />
              </a>
            </div>
          </div>
          <div className="find">find us on</div>
        </div>
        <div className="container footer-credits-main">
          Designed and Developed by{" "}
          <a href="https://wyld.global/" target="_blank">
            WYLD
          </a>
          .
        </div>
      </div>
    </div>
  )
}

export default Footer
